'use client';

import { Disclosure } from '@headlessui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { signOut } from 'next-auth/react';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { CgExternal } from "react-icons/cg";
import { GiStarSwirl } from "react-icons/gi";
import { IoIosLogIn, IoIosLogOut } from "react-icons/io";
import { RiCoinsLine } from "react-icons/ri";
import { useMedia } from 'react-use';
import tailwindConfig from 'tailwind.config';
import resolveConfig from "tailwindcss/resolveConfig";
import { Config } from "tailwindcss/types/config";
import useNavbarObserver from '../hooks/useNavbarObserver';
import { DeforumWebAppUser } from 'pages/api/auth/[...nextauth]';
import { useTheme } from './components/ThemeProvider';
import ThemeToggle from './components/ThemeToggle';
import { Tooltip, TooltipProps } from 'flowbite-react';
import { PiKeyBold } from "react-icons/pi";
import { PiCoinVerticalBold } from "react-icons/pi";
import { useSearchParams } from 'next/navigation';
import { PiPlusSquareBold } from "react-icons/pi";
import { PiFolderBold } from "react-icons/pi";
import { PiCompassBold } from "react-icons/pi";
import { PiCodeBold } from "react-icons/pi";
import _ from 'lodash';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

type NavbarProps = {
  user: DeforumWebAppUser | null;
  inputIsAdmin: boolean,
  creditBalance: string | undefined,
  defaultOpen: boolean
  theme: string | null
};

export function Navbar({ user, inputIsAdmin, creditBalance, defaultOpen }: NavbarProps) {
  useNavbarObserver();
  const searchParams = useSearchParams();

  const is_admin = inputIsAdmin && !searchParams?.get("likeauser");

  const { theme } = useTheme();

  const pathname = usePathname();
  const fullConfig = resolveConfig(tailwindConfig as unknown as Config);
  const breakpoints = fullConfig?.theme?.screens || {
    xs: "480px",
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
  };
  const isMobile = useMedia(`(max-width: ${breakpoints.lg})`, false);

  const navigation = [
    { name: 'Create', href: '/animate', icon: PiPlusSquareBold, condition: user, external: false },
    { name: 'Explore', href: '/explore', icon: PiCompassBold, condition: true, external: false },
    { name: 'Get Started', href: '/', icon: GiStarSwirl, condition: !user, external: false },
    { name: 'My Library', href: '/library', icon: PiFolderBold, condition: !!user, external: false },
    { name: 'Credits', href: '/settings', icon: PiCoinVerticalBold, condition: !!user, emphasis: !creditBalance || parseFloat(creditBalance) < 6, external: false},
    { name: 'API', href: '/api-doc', icon: PiCodeBold, condition: user, external: false },
    { name: 'Admin', href: '/admin', icon: PiKeyBold, condition: is_admin, external: false },
  ].filter(item => item.condition);

  return (
    <Disclosure
      as="nav"
      defaultOpen={defaultOpen}
      className={({ open }) => classNames('z-40 sticky top-0 shrink-0 flex flex-col justify-between w-full  max-w-full lg:max-w-48 lg:h-screen text-white bg-white shadow-md dark:shadow-slate-800 dark:bg-black',
        open ? 'lg:w-48 lg:min-w-48' : 'lg:w-16', '')}
    >
      
      {
        // eslint-disable-next-line @typescript-eslint/unbound-method
        ({ open, close}) => (
        <>
          {/* Nav: Logo+links */}
          <div>
            <div className={classNames("flex flex-col items-center lg:mt-4 lg:mb-10", open ? "block" : "lg:mt-4 lg:ml-1")}>
              <div className='relative flex justify-center'>
                <Link href="/">
                  <Image
                    className="block"
                    src={theme === 'dark' ? "/logo.png" : "/logo-light.png"}
                    alt="logo"
                    height={open ? 100 : 50}
                    width={open ? 100 : 50}
                  />
                </Link>
                </div>
                {
                  open && <div className={classNames("text-center font-aldrich text-black dark:text-white mb-4 lg:mb-0")}>
                    deforum studio
                  </div>
                }
            </div>

            <div className={classNames("flex flex-col items-center lg:items-start lg:pl-0", open ? "block lg:pl-6" : "hidden lg:block")}>
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  href={item.href}
                  target={item.external ? "_blank" : undefined} rel={item.external ? "noopener noreferrer" : undefined}
                  onClick={() => setTimeout(() => isMobile ? close() : {}, 100)}
                >
                  <div
                    className={classNames('inline-flex items-center w-full cursor-pointer',
                      pathname === item.href
                        ? 'text-black dark:text-white font-semibold'
                        : 'border-transparent text-neutral-600 hover:ease-linear hover:duration-200 hover:text-black dark:text-neutral-400 dark:hover:text-neutral-200 ',
                      open
                        ? 'justify-start px-1 pt-1 xs:text-lg xsm:text-2xl lg:text-xl pb-1'
                        : 'my-2 justify-center'
                    )}
                    aria-current={pathname === item.href ? 'page' : undefined}
                  >
                    {item.icon
                      && <div className='whitespace-nowrap'>
                        <OptionalTooltip hidden={open} content={item.name} placement="right" style="light" animation="duration-500" arrow={false}>
                          <item.icon className={classNames("h-6 w-6", item.emphasis ? 'text-yellow-500 dark:text-yellow-300' : '')} />
                        </OptionalTooltip>
                      </div>
                    }
                    {open && <span className="ml-2">{item.name}&nbsp;</span>}
                    {open && item.external && <CgExternal className="inline" />}
                  </div>
                </Link>
              ))}
            </div>
          </div> {/* Nav: Logo+links */}

          {/* Nav: profile, theme toggle, signin/out */}
          <div className={classNames('mb-6 xs:mb-6 sm:mb-8 lg:mb-10 text-xl items-center lg:items-start', open ? 'block' : 'hidden lg:block')}>

            <div className="flex flex-col items-center text-center mt-10">
              {user
                && <div className='whitespace-nowrap'>
                  <OptionalTooltip hidden={open} content="User Settings" placement="right" style="light" animation="duration-500" arrow={false}>
                    <Link
                      href="/settings"
                      onClick={() => setTimeout(() => isMobile ? close() : {}, 100)}
                    >
                      <Image
                        className={classNames('h-6 w-6 rounded-full mb-0 mx-auto')}
                        src={user.image || 'https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a6918e57475a843f59f_icon_clyde_black_RGB.svg'}
                        height={32}
                        width={32}
                        alt={`${user.name} avatar`}
                      />
                      {open && (
                        <div className="font-medium text-neutral-600 hover:ease-linear hover:duration-200 hover:text-black dark:text-neutral-400 dark:hover:text-neutral-200 mb-1 overflow-hidden">{_.truncate(user.name || 'Unknown Artist', {length:100})}</div>
                      )}

                      {open && creditBalance !== null && (
                        <div className={classNames('font-medium text-neutral-600 dark:text-neutral-200', open ? 'text-m' : 'text-sm')}>
                          <RiCoinsLine className={'inline mr-1'} />
                          <strong>{creditBalance}</strong>
                        </div>
                      )}
                    </Link>
                  </OptionalTooltip>
                </div>
              }
            </div>


            <div className="mt-6 flex flex-col items-center text-2xl whitespace-nowrap">
              <OptionalTooltip content={(theme == 'dark' ? 'Light' : 'Dark') + ' Mode'} placement="right" style="light" animation="duration-500" arrow={false}>
                <ThemeToggle />
              </OptionalTooltip>
            </div>

            <div className={classNames('flex flex-col items-center border-transparent text-neutral-600 hover:ease-linear hover:duration-200 hover:text-black dark:text-neutral-400 dark:hover:text-neutral-200 w-full cursor-pointer',
              'xs:text-lg xsm:text-2xl lg:text-xl block')}>
              {user
                ? <button
                  onClick={() => {
                    setTimeout(() => isMobile ? close() : {}, 100);
                    void signOut();
                  }}
                  className={classNames("whitespace-nowrap mt-4 flex gap-1 items-center")}>
                  <OptionalTooltip hidden={open} content="Sign Out" placement="right" style="light" animation="duration-500" arrow={false}>
                    <IoIosLogOut className='h-6 w-6 inline' />
                  </OptionalTooltip>
                  {open && <span> Sign out</span>}
                </button>
                : <Link href="/signin"
                  onClick={() => setTimeout(() => isMobile ? close() : {}, 100)}
                  className={classNames("whitespace-nowrap mt-6 flex gap-1 items-center")}>
                  <OptionalTooltip hidden={open} content="Sign In" placement="right" style="light" animation="duration-500" arrow={false}>
                    <IoIosLogIn className='h-6 w-6 inline' />
                  </OptionalTooltip>
                  {open && <span className="ml-2"> Sign in</span>}
                </Link>
              }
            </div>

            {open && <div className="text-center text-xs mt-6 text-neutral-500">
              help@deforum.art
            </div>}

          </div> {/* Nav: profile, signin/out, theme toggle */}

          <div className={classNames("fixed right-0 top-0 lg:top-5 -z-1", open ? "lg:left-48" : "lg:left-16")}>
            <Disclosure.Button className="bg-transparent w-screen flex justify-end lg:w-fit lg:flex-none lg:justify-start">
              <span className="sr-only">Open main menu</span>
              {open ? (
                <ChevronLeftIcon className="text-black dark:text-white bg-neutral-300 dark:bg-neutral-700 block h-12 w-12 lg:h-8 lg:w-8 rotate-90 lg:rotate-0" aria-hidden="true" />
              ) : (
                <ChevronRightIcon className="text-black dark:text-white bg-neutral-300 dark:bg-neutral-700 block h-12 w-12 lg:h-8 lg:w-8 rotate-90 lg:rotate-0" aria-hidden="true" />
              )}
            </Disclosure.Button>
          </div>
        </>
      )}
    </Disclosure>
  );
}

const OptionalTooltip = (props: TooltipProps) => {
  return props.hidden
    ? <>{props.children}</>
    : <Tooltip {...props} >{props.children}</Tooltip>
}
