import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/components/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/app/components/ThemeProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Navbar"] */ "/vercel/path0/app/navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.3.1_next@14.2.6_@babel+core@7.24.6_@playwright+test@1.46.1_babel-plugin-m_zt67luq2hya74unoxr5fnyv4qi/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.0.12_next@14.2.6_@babel+core@7.24.6_@playwright+test@1.46.1_babel-pl_5onlqouawfu42k7bt7pgk2vsda/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/jotai@2.9.3_@types+react@18.3.4_react@18.3.1/node_modules/jotai/esm/react.mjs");
