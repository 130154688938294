import React from 'react';
import { FaMoon, FaSun } from 'react-icons/fa';
import { useTheme } from './ThemeProvider';

const ThemeToggle = () => {

 const {theme, toggleTheme}  = useTheme();

  return (
    <button onClick={toggleTheme}>
      {theme === 'dark'
        ? <FaSun className="text-neutral-400 hover:ease-linear hover:duration-300 hover:text-white" />
        : <FaMoon className="text-neutral-400 hover:ease-linear hover:duration-300 hover:text-black" />}
    </button>
  );
};

export default ThemeToggle;