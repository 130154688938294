'use client';
import { Footer } from 'flowbite-react';
import { BsDiscord, BsGithub, BsInfoCircle, BsInstagram, BsTwitter, BsYoutube } from 'react-icons/bs';

export default function DeforumFooter() {
    return (
        <Footer container className="z-20 rounded-none md:fixed md:bottom-0 w-full md:py-6 bg-custom-light dark:bg-custom-dark pr-7">
            <div className="w-full">
                <div className="flex flex-row items-center justify-between">
                    <div className="flex-shrink-0"></div>
                    <div className="flex flex-row items-center space-x-6">
                        <Footer.LinkGroup className='gap-4 hidden md:flex text-neutral-500 dark:text-neutral-500'>
                            <Footer.Link href="mailto:help@deforum.art" target="_blank" rel="noopener noreferrer">Help</Footer.Link>
                            <Footer.Link href="/privacy">Privacy Policy</Footer.Link>
                            <Footer.Link href="/terms">Terms of Service</Footer.Link>
                            <Footer.Link href="https://deforum.art/info.html" target="_blank" rel="noopener noreferrer">About</Footer.Link>
                        </Footer.LinkGroup>
                        <div className="flex gap-4">
                            <Footer.Icon className='sm:hidden inline' href="https://deforum.art" icon={BsInfoCircle} target="_blank" rel="noopener noreferrer" />
                            <Footer.Icon className='hover:ease-linear hover:duration-200 text-neutral-500 dark:text-neutral-500' href="https://discord.gg/deforum" icon={BsDiscord} target="_blank" rel="noopener noreferrer" />
                            <Footer.Icon className='hover:ease-linear hover:duration-200 text-neutral-500 dark:text-neutral-500' href="https://twitter.com/deforum_art" icon={BsTwitter} target="_blank" rel="noopener noreferrer" />
                            <Footer.Icon className='hover:ease-linear hover:duration-200 text-neutral-500 dark:text-neutral-500' href="https://instagram.com/deforum_art" icon={BsInstagram} target="_blank" rel="noopener noreferrer" />
                            <Footer.Icon className='hover:ease-linear hover:duration-200 text-neutral-500 dark:text-neutral-500' href="https://www.youtube.com/@deforum_art" icon={BsYoutube} target="_blank" rel="noopener noreferrer" />
                            <Footer.Icon className='hover:ease-linear hover:duration-200 text-neutral-500 dark:text-neutral-500' href="https://github.com/deforum-art" icon={BsGithub} target="_blank" rel="noopener noreferrer" />
                        </div>
                    </div>
                </div>
            </div>
        </Footer>
    );
}
