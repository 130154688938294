
import { useEffect } from 'react';
import { useCookie } from 'react-use';

const useNavbarObserver = () => {
  
  const [, setNavbar] = useCookie('isNavbarOpen');

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'data-headlessui-state') {
          //@ts-expect-error - mutation.target type may not have a .getAttribute() but we know it will in this case
          const state = mutation.target.getAttribute('data-headlessui-state') as string;
          setNavbar(state === 'open' ? 'true' : 'false');
        }
      });
    });

    const navElement = document.querySelector('nav[data-headlessui-state]');
    if (navElement) {
      observer.observe(navElement, { attributes: true });
    }

    return () => {
      observer.disconnect();
    };
  }, [setNavbar]);

  return null;
};

export default useNavbarObserver;
