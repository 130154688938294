"use client";

import React, { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useCookie } from 'react-use';

export type Theme = 'dark' | 'light';

interface ThemeContextType {
  theme: Theme;
  toggleTheme: () => void;
}

const ThemeContext = createContext<ThemeContextType>({ theme: 'dark', toggleTheme: () => {} });

export const useTheme = () => useContext(ThemeContext);

interface ThemeProviderProps {
  defaultTheme: Theme;
  children: ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ defaultTheme, children }) => {
  const [, setPersistedTheme] = useCookie('theme');
  const [theme, setTheme] = useState<'light' | 'dark'>(defaultTheme);

  const toggleTheme = useCallback(() => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    setPersistedTheme(newTheme); 
    setTheme(newTheme);
  }, [setPersistedTheme, theme]);

  useEffect(() => {
    document.documentElement.className = theme;
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};